import { CCApiService } from './api-service';
import { UIConfig, SAMLClientUserSSOForm, SAMLClientUserSSOResponse, OIDCClientUserSSOForm, OIDCClientUserSSOResponse  } from './interfaces';
import { ApiError } from '../../errors';
import { OIDCSampleResponse, SAMLSampleResponse } from '../../components/users-and-provisioning/mock-data';

export class ClientUserSSOManagementService {
  static ApiService: CCApiService;
  static customersAPI: string;
  static apiBasePath: string;
  static samlAPI: string;
  static oidcAPI: string;

  static init(config: UIConfig, customerId: string) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = config.apiBasePath;
    this.customersAPI = `${this.apiBasePath}/customers/${customerId}`;
    this.samlAPI = `${this.customersAPI}/saml-client-user-sso`;
    this.oidcAPI = `${this.customersAPI}/oidc-client-user-sso`;
  }

  static async getClientUserSSO(mockMode = false): Promise<SAMLClientUserSSOResponse[] | OIDCClientUserSSOResponse[]> {
    if (mockMode) {
      return Promise.resolve([SAMLSampleResponse] as SAMLClientUserSSOResponse[]) 
    }
    return this.ApiService.performFetch(`${this.customersAPI}/client-user-sso`).then(entities => {
      return entities;
    });
  }

  static async newSamlSso(body: SAMLClientUserSSOForm, mockMode = false): Promise<{content: SAMLClientUserSSOResponse}> {
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(body),
    };
    if (mockMode) {
      return Promise.resolve({ content: SAMLSampleResponse as SAMLClientUserSSOResponse })
    }
    return this.ApiService.performFetch(this.samlAPI, fetchOptions).catch(
      this._mapApiErrorCodeToMessage
    );
  }

  static async newOicdSso(body: OIDCClientUserSSOForm, mockMode = false): Promise<{content: OIDCClientUserSSOResponse}> {
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(body),
    };
    if (mockMode) {
      return Promise.resolve({ content: OIDCSampleResponse as OIDCClientUserSSOResponse })
    }
    return this.ApiService.performFetch(this.oidcAPI, fetchOptions).catch(
      this._mapApiErrorCodeToMessage
    );
  }

  static async updateSamlSso(body: SAMLClientUserSSOForm, mockMode = false): Promise<SAMLClientUserSSOResponse> {
    const fetchOptions = {
      method: 'PUT',
      body: JSON.stringify(body),
    };
    if (mockMode) {
      return Promise.resolve(SAMLSampleResponse as SAMLClientUserSSOResponse)
    }
    return this.ApiService.performFetch(`${this.samlAPI}`, fetchOptions).catch(
      this._mapApiErrorCodeToMessage
    );
  }

  static async updateOicdSso(body: OIDCClientUserSSOForm, mockMode = false): Promise<OIDCClientUserSSOResponse> {
    const fetchOptions = {
      method: 'PUT',
      body: JSON.stringify(body),
    };
    if (mockMode) {
      return Promise.resolve(OIDCSampleResponse as OIDCClientUserSSOResponse)
    }
    return this.ApiService.performFetch(`${this.oidcAPI}`, fetchOptions).catch(
      this._mapApiErrorCodeToMessage
    );
  }

  static async getOIDCClientUserSSO(): Promise<OIDCClientUserSSOResponse> {
    return this.ApiService.performFetch(`${this.oidcAPI}`).then(entities => {
      return entities;
    });
  }

  static async getSAMLClientUserSSO(): Promise<SAMLClientUserSSOResponse> {
    return this.ApiService.performFetch(`${this.samlAPI}`).then(entities => {
      return entities;
    });
  }

  static async deleteSamlSSO(mockMode = false): Promise<void> {
    const fetchOptions = {
      method: 'DELETE',
    };
    if (mockMode) {
      return Promise.resolve();
    }
    return this.ApiService.performFetch(`${this.samlAPI}`, fetchOptions);
  }

  static async deleteOicdSSO(mockMode = false): Promise<void> {
    const fetchOptions = {
      method: 'DELETE',
    };
    if (mockMode) {
      return Promise.resolve();
    }
    return this.ApiService.performFetch(`${this.oidcAPI}`, fetchOptions);
  }

  static _mapApiErrorCodeToMessage(error) {
    if (error instanceof ApiError && error.message === 'E0000038') {
      error.message = 'The user cannot be added. Please contact customer support.';
    }
    throw error;
  }

}
