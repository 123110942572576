import { OIDCClientUserSSOResponse, SAMLClientUserSSOResponse } from '../../services/api/interfaces';

export const OIDCSampleFormData = {
  clientId: "5f5e4e5d7d8b7d001b4a1d3e",
  clientSecret: "5f5e4e5d7d8b7d001b4a1d3e",
  emailDomains: ['test-domain'],
  configurationUrl: 'test.com',
  issuerUrl: 'issuer url',
  authorizationUrl: 'v',
  tokenUrl: 'v',
  userinfoUrl: 'v',
  jwksUrl: 'v',
}

export const OIDCSamplePostBody = {
  name: 'OIDC',
  clientId: "5f5e4e5d7d8b7d001b4a1d3e",
  clientSecret: "5f5e4e5d7d8b7d001b4a1d3e",
  emailDomains: ['test-domain'],
  configurationUrl: 'test.com',
  customDataFields: {
    issuerUrl: 'issuer url',
    authorizationUrl: 'v',
    tokenUrl: 'v',
    userinfoUrl: 'v',
    jwksUrl: 'v',
  }
}

export const OIDCSampleResponse =  {
  "id": "3f923335-f2e1-4ced-ad64-4000541732db",
  "name": "OIDC",
  "type": "OIDC",
  "customer": {
      "id": "3f923335-f2e1-4ced-ad64-4000541732db",
      "name": "Engineering"
  },
  "emailDomains": [
      "test-domain"
  ],
  "state": "COMPLETED",
  "description": "Engineering OIDC Client User SSO",
  "configurationUrl": "test.com",
  "customDataFields": {
      "issuerUrl": "issuer url",
      "authorizationUrl": "v",
      "tokenUrl": "v",
      "userinfoUrl": "v",
      "jwksUrl": "v"
  },
  credentials: {
    clientId: "5f5e4e5d7d8b7d001b4a1d3e",
    clientSecret: "5f5e4e5d7d8b7d001b4a1d3e"
  },
  identityProviderAuthorizeUrl: 'authorize url',
  identityProviderRedirectUri: 'redirect url',
} as OIDCClientUserSSOResponse;

export const SAMLSampleFormData = {
  endpoints: 'Automated',
  emailDomains: ['test-domain'],
  metadataUrl: 'metadata url',
  ssoUrl: 'asdf',
  issuerUrl: 'fdsa',
  certificate: 'asdkjffjdkslaks',
}

export const SAMLSamplePostBody = {
  name: 'SAML',
  emailDomains: ['test-domain'],
  metadataUrl: 'metadata url',
  customDataFields: {
    ssoUrl: 'asdf',
    issuerUrl: 'fdsa',
    certificate: 'asdkjffjdkslaks'
  }
}

export const SAMLSampleResponse =  {
  "id": "a7146258-23f5-4c51-baf2-2fabd38abb8b",
  "name": "a7146258-23f5-4c51-baf2-2fabd38abb8b_SAML",
  "type": "SAML",
  "customer": {
      "id": "a7146258-23f5-4c51-baf2-2fabd38abb8b",
      "name": "Rosemary Demo"
  },
  "emailDomains": [
      "gmail.com"
  ],
  "state": "COMPLETED",
  "description": "Rosemary Demo SAML Client User SSO",
  "metadataUrl": "https://lucidworkstest.oktapreview.com/app/exk25wzy0o2QfvHy10h8/sso/saml/metadata",
  "customDataFields": {
      "ssoUrl": "https://lucidworkstest.oktapreview.com/app/lucidworkstest_contoso_1/exk25wzy0o2QfvHy10h8/sso/saml",
      "issuerUrl": "issuerUTL",
      "certificate": "MIIDrDCCApSgAwIBAgIGAZFOYhHXMA0GCSqGSIb3DQEBCwUAMIGWMQswCQYDVQQGEwJVUzETMBEG\nA1UECAwKQ2FsaWZvcm5pYTEWMBQGA1UEBwwNU2FuIEZyYW5jaXNjbzENMAsGA1UECgwET2t0YTEU\nMBIGA1UECwwLU1NPUHJvdmlkZXIxFzAVBgNVBAMMDmx1Y2lkd29ya3N0ZXN0MRwwGgYJKoZIhvcN\nAQkBFg1pbmZvQG9rdGEuY29tMB4XDTI0MDgxNDAwNTQzNVoXDTM0MDgxNDAwNTUzNVowgZYxCzAJ\nBgNVBAYTAlVTMRMwEQYDVQQIDApDYWxpZm9ybmlhMRYwFAYDVQQHDA1TYW4gRnJhbmNpc2NvMQ0w\nCwYDVQQKDARPa3RhMRQwEgYDVQQLDAtTU09Qcm92aWRlcjEXMBUGA1UEAwwObHVjaWR3b3Jrc3Rl\nc3QxHDAaBgkqhkiG9w0BCQEWDWluZm9Ab2t0YS5jb20wggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAw\nggEKAoIBAQCmjj8ZeTsLkzFDBl8GLKkRqqxPL75S+z5vvY0YCc4rJM2o6NAb2heEeOItzO62a/Yg\n5RtPn/HQmbX3Z5eBI0033McKpI3/fnn9HygtGfDZ9y+nGnG4WA4sV9IACogZJs032lnd4AOztZ59\n8TyxD+WMfkQx6cqAXGOK2ZAjfnOhhLJzOXXduVY1BRa5rA/TKBpvhqiCvhgjiLnmjUqRZUOtOE9F\nZ14l96bc6hWDZ/ujoW8IPtty0l0wlbISUCes+ka+9XsSBUdQDGJQ1IDJ7cF3W4I6sDEzJk/3s832\n22e+JkeYjdD5uEf7BJRWe6jS8kEx+xd9TGTV43R0l6a4IUHBAgMBAAEwDQYJKoZIhvcNAQELBQAD\nggEBABn3i4ya8OJQr0u0JJELo1huwc9i9usdBY4RuEXLqFl8QaAX5Z5YgivUcL02X3WSmG92i7jV\n6m76MiMwDakyV5bPWXGWjuqlR8rNYeNu3r22B89V2o8QA36qezrDzjTmqkbqKOSuMf11WcLq6UuG\nR1oPKTNQaGHpeU1/OufCPI0rdgTf/gVbbE1wG1jBaH8weFyCbcweIb0+NvIpXERmOJ9vYxBEzqyx\n8rFeqmwlHN3q7rgNdo2MRufv9CBZgVQGTlizqeKMCnGClvIGFhSdCEfo4S79T3DM/Z3n0/XXgI47\nuWjOCzcZEDZKwzVbsUXd0lPSuMa9jx4kAo8xDhOVxas="
  },
  "identityProviderAssertionConsumersServiceUrl": 'assertion url',
  "identityProviderAudienceUri": 'audience url',
} as SAMLClientUserSSOResponse;